import InputAdornment from '@material-ui/core/InputAdornment'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { CSSProperties } from 'react'
import { SizeProps } from '../CustomModal'
import SearchIcon from '../Icon/svg/SearchIcon'

export default function SearchBar(props: {
    value: string
    placeholder?: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    styles?: CSSProperties
    width?: SizeProps['size']
    height?: SizeProps['size']
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}) {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            textInputRoot: {
                borderRadius: 5,
                width: props.width || 'auto',
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'center',
                '& > div': {
                    height: props.height || 'auto',
                },
            },
            iconButtonRoot: {
                padding: 0,
                margin: 10,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        }),
    )

    const classes = useStyles()

    return (
        <>
            <TextField
                autoComplete="off"
                size="small"
                variant="outlined"
                style={props.styles}
                classes={{ root: classes.textInputRoot }}
                id="input-with-icon-textfield"
                value={props.value}
                placeholder={props.placeholder || ''}
                onChange={
                    props.onChange
                        ? props.onChange
                        : (e: React.ChangeEvent<HTMLInputElement>) => props.setValue(e.target.value)
                }
                onFocus={props.onFocus}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    )
}
